<app-header></app-header>
<div *ngIf="isLoading" class="preloader">
    <div class="pyramid-loader">
        <div class="wrapper">
          <span class="side side1"></span>
          <span class="side side2"></span>
          <span class="side side3"></span>
          <span class="side side4"></span>
          <span class="shadow"></span>
        </div>  
      </div>
  </div>
<router-outlet></router-outlet>
<app-footer></app-footer>